import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import WalletsFiltersContent from './WalletsFiltersContent';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
/* eslint-disable @typescript-eslint/no-unsafe-call, prefer-destructuring,
  @typescript-eslint/prefer-regexp-exec, @typescript-eslint/no-unsafe-return */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["UPDATE"] = "UPDATE";
    FilterActionType["DELETE"] = "DELETE";
    FilterActionType["REMOVE"] = "REMOVE";
})(FilterActionType || (FilterActionType = {}));
const init = () => ({
    filters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { filters: [...state.filters, action.item] };
        case FilterActionType.DELETE:
            return {
                filters: state.filters.filter((f) => f.label !== action.item.label),
            };
        case FilterActionType.UPDATE: {
            const objIndex = state.filters.findIndex((obj) => obj.name === action.item.name);
            const newArr = state.filters;
            newArr[objIndex].value = action.item.value;
            newArr[objIndex].label = action.item.label;
            return { filters: newArr };
        }
        case FilterActionType.REMOVE: {
            const objIndex = state.filters.findIndex((obj) => obj.name === action.item.name);
            const newArr = state.filters;
            newArr.splice(objIndex);
            return { filters: newArr };
        }
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const WalletsFilters = ({ selectedClient, onFiltersChange, initialValue = '', toggleModal, hideZero, features, setWithMigrated, withMigrated, allCurrencies, toggleZeroBalances, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const addSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const updateSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.UPDATE, item: val });
    };
    const removeFilter = (val) => {
        dispatch({ type: FilterActionType.REMOVE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const handleCurrencyChange = (option) => {
        const item = {
            label: option ? option.label : '',
            name: 'currency_code',
            type: 'currency',
            value: option ? option.value : '',
        };
        if (!option) {
            removeFilter(item);
            return;
        }
        const isFilter = state.filters.find((f) => f.name === 'currency_code');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleStateChange = (option) => {
        const item = {
            label: option ? option.value : '',
            name: 'state',
            type: 'state',
            value: option ? option.value : '',
        };
        if (!option) {
            removeFilter(item);
            return;
        }
        const isFilter = state.filters.find((f) => f.name === 'state');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    useEffect(() => {
        onFiltersChange(state.filters);
    }, [state]);
    useEffect(() => {
        resetFilters();
    }, [selectedClient]);
    return (React.createElement(WalletsFiltersContent, { state: state, hideZero: hideZero, features: features, toggleModal: toggleModal, resetFilters: resetFilters, withMigrated: withMigrated, allCurrencies: allCurrencies, setWithMigrated: setWithMigrated, handleStateChange: handleStateChange, toggleZeroBalances: toggleZeroBalances, removeSelectedFilter: removeSelectedFilter, handleCurrencyChange: handleCurrencyChange }));
};
const mapStateToProps = (state) => ({
    allCurrencies: getCustodyCurrenciesOptions(state),
    selectedClient: getSelectedClientInputValue(state),
});
const mapDispatchToProps = () => ({
    initialValue: '',
});
export default connect(mapStateToProps, mapDispatchToProps)(WalletsFilters);
